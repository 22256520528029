import React, { useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Compare, FFFStorage, useUserContext } from '@fff-web/fff-utilities';
import GlobalNavInternalContext from '../../context/GlobalNavInternalContext';
import { PUBLIC_SHOP_BANNER } from '../../utils/banners';
import { getBanner, getDismissBannerKey } from '../../services';
import './Banner.css';

const isValidBanner = (banner) =>
  typeof banner === 'object' &&
  banner.id >= 0 &&
  banner.title &&
  banner.subText;

const getBannerFromLocalStorage = () => {
  const savedBanner = FFFStorage.getFromStorage({ key: 'banner' });
  if (savedBanner && isValidBanner(savedBanner)) {
    return savedBanner;
  }
  return {
    subText: '',
    published: false,
    id: 0,
    title: '',
    createdAt: '',
    lastEditedAt: '',
    lastEditedBy: '',
    hideBanner: true,
  };
};

export const Banner = ({ isPublicShop }) => {
  const { userInfo } = useUserContext();
  const [info, setInfo] = useState(getBannerFromLocalStorage());
  const { env } = useContext(GlobalNavInternalContext);
  const shouldRenderBanner =
    (userInfo.loggedIn && !info.hideBanner) || isPublicShop;

  const handleSetInfo = useCallback((newBannerInfo) => {
    if (isPublicShop) {
      setInfo(PUBLIC_SHOP_BANNER);
      return;
    }
    if (
      isValidBanner(newBannerInfo) &&
      !Compare.isEqualObject(newBannerInfo, info)
    ) {
      setInfo(newBannerInfo);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleHideBanner = (id) => {
    FFFStorage.setToStorage({ key: getDismissBannerKey(id), value: 'yes' });
    const banner = { ...info, hideBanner: true };
    FFFStorage.setToStorage({ key: 'banner', value: banner });
    setInfo(banner);
    // This Event help NavigationMenu to render the height properly
    setTimeout(() => window.dispatchEvent(new Event('resize')), 1);
  };
  useEffect(() => {
    getBanner(env)
      .then((newBannerInfo) => handleSetInfo(newBannerInfo))
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, []);

  return (
    shouldRenderBanner && (
      <div
        data-testid="banner"
        className={classNames('db-container', {
          publicShopBanner: isPublicShop,
        })}
      >
        <div className="db-container-message">
          <div className="db-title">
            {!isPublicShop && (
              <p className="db-title-exclamation">
                <i className="far fa-exclamation-circle"> </i>
              </p>
            )}
            <p data-testid="banner-title" className="db-title-text">
              {info.title}
            </p>
          </div>
          <p data-testid="banner-subtitle" className="db-message">
            {info.subText}
          </p>
        </div>
        {!isPublicShop && (
          <button
            className="db-container-close"
            type="button"
            onClick={() => handleHideBanner(info.id)}
          >
            <p className="db-close">
              <i className="far fa-times" />
            </p>
          </button>
        )}
      </div>
    )
  );
};

Banner.defaultProps = {
  isPublicShop: false,
};

Banner.propTypes = {
  isPublicShop: PropTypes.bool,
};
