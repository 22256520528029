// eslint-disable-next-line import/no-extraneous-dependencies
import { track as segmentTracking } from '@fff-web/fff-analytics';

function track(eventName, trackingData) {
  try {
    const userId = trackingData.user_id?.toString();
    const id = { Optimizely: { userId } };

    segmentTracking({
      trackName: eventName,
      properties: {
        ...trackingData,
        id,
      },
    });
  } catch (e) {
    console.error(e);
  }
}

export default track;

export const segmentEvents = {
  checkoutStarted: 'Checkout Started',
  cartViewed: 'Cart Viewed',
  productAdded: 'Product Added',
  productRemoved: 'Product Removed',
};
