import React from 'react';

import { getCampaign, TABS } from '../../../utils/campaign';
import Tab from './Tab';
import styles from './Tabs.module.css';

const Tabs = ({
  activeCampaign,
  activeTab,
  setActiveTab,
  saleItemsCount,
  shopItemsCount,
  isNewMemberOnboarding,
  showSecondaryTab,
}) => {
  const campaign = getCampaign(activeCampaign, isNewMemberOnboarding);
  const secondCampaign = TABS.SHOP;

  return (
    <div className={styles.container}>
      <Tab
        // TODO change this from spreading to normal props
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...campaign}
        active={activeTab === 0}
        quantity={campaign.id === TABS.UPCOMING.id ? 0 : saleItemsCount}
        onClick={() => setActiveTab(0)}
        onlyTab={!showSecondaryTab}
      />
      {showSecondaryTab && (
        <Tab
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...secondCampaign}
          active={activeTab === 1}
          quantity={shopItemsCount}
          onClick={() => setActiveTab(1)}
        />
      )}
    </div>
  );
};

export default Tabs;
