import React, { useState } from 'react';
import * as newMemberStates from '../helpers/constants/newMemberStates';

export const universalNavigationContextDefaultProps = {
  onNewMemberStateFetched: () => {},
  newMemberState: null,
  isNewMemberFlowFinished: true,
  isNewMember: false,
  newMemberStateLoading: true,
};

export const UniversalNavigationContext = React.createContext({
  ...universalNavigationContextDefaultProps,
});

export const UniversalNavigationContextProvider = ({ children }) => {
  const [newMemberState, setNewMemberState] = useState(null);
  const [newMemberStateLoading, setNewMemberStateLoading] = useState(true);

  const isNewMember =
    newMemberState &&
    ![
      newMemberStates.PASSIVE_CHECKOUT,
      newMemberStates.ACTIVE_CHECKOUT,
      newMemberStates.TIMER_COMPLETED,
    ].includes(newMemberState);

  const isNewMemberFlowFinished =
    !newMemberState ||
    [
      newMemberStates.READY_FOR_SALE,
      newMemberStates.USER_ONBOARDING_1,
      newMemberStates.USER_ONBOARDING_2,
      newMemberStates.USER_ONBOARDING_3,
      newMemberStates.USER_ONBOARDING_4,
      newMemberStates.USER_COMPLETED,
      newMemberStates.PASSIVE_CHECKOUT,
      newMemberStates.ACTIVE_CHECKOUT,
      newMemberStates.TIMER_COMPLETED,
    ].includes(newMemberState);

  const onNewMemberStateFetched = (value) => {
    setNewMemberState(value);
    setNewMemberStateLoading(false);
  };

  return (
    <UniversalNavigationContext.Provider
      value={{
        newMemberState,
        newMemberStateLoading,
        onNewMemberStateFetched,
        isNewMemberFlowFinished,
        isNewMember,
      }}
    >
      {children}
    </UniversalNavigationContext.Provider>
  );
};

export const useUniversalNavigationContext = () => {
  return React.useContext(UniversalNavigationContext);
};
