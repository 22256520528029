import React from 'react';

const DataContext = React.createContext({
  userData: {},
  initialCredits: {},
  onClickProduct: () => {},
  shoppingCreditFF: null,
  isNewMemberOnboarding: null,
  isNewMemberFlow: null,
  newMemberState: null,
});

const DataContextProvider = ({
  userData,
  initialCredits,
  onClickProduct,
  children,
  shoppingCreditFF,
  isNewMemberOnboarding,
  isNewMemberFlow,
  newMemberState,
}) => (
  <DataContext.Provider
    // TODO: Add useMemo here
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    value={{
      userData,
      initialCredits,
      onClickProduct,
      shoppingCreditFF,
      isNewMemberOnboarding,
      isNewMemberFlow,
      newMemberState,
    }}
  >
    {children}
  </DataContext.Provider>
);

export const useDataContext = () => {
  const context = React.useContext(DataContext);
  return context;
};

export default DataContextProvider;
