import React, { useContext, useState, useEffect, useMemo } from 'react';

import { isMobile } from 'react-device-detect';

import { track, identify } from '@fff-web/fff-analytics';
import { useUserContext } from '@fff-web/fff-utilities';

import { getAppMobileLink } from '../../utils/dynamicLinks';
import WithABTest from '../WithABTest';
import {
  OPTIMIZELY_FEATURES_FLAGS,
  MOBILE_BANNER_VARIATIONS,
} from '../../utils/configurations';
import GlobalNavInternalContext from '../../context/GlobalNavInternalContext';
import fffAppLogo from './fff-app-logo.png';
import { VARIATIONS } from '../../utils/constants';

import styles from './MobileWebBanner.module.css';

const TRACK_DEVICE = 'Mobile Web';
const TRACK_EXPERIENCE = 'smart app banner';
const CTA_BUTTON_TEXT = 'Open';
const BLACKLIST = ['reactivate-my-membership', 'customize', 'boosts'];

const MobileWebBanner = ({ experimentName, variationName, isVariations }) => {
  const [isMobileBannerOpen, toggleMobileBanner] = useState(true);
  const { userInfo } = useUserContext();
  const { env, flags } = useContext(GlobalNavInternalContext);
  const { downloadMobileAppWebBannerFF: downloadMobileAppBanner } = flags;
  const isLoggedIn = Boolean(userInfo?.loggedIn);
  const appMobileLink = getAppMobileLink(env);
  const showABTest = variationName === VARIATIONS.ON || isVariations;

  const pathname = useMemo(() => window?.location?.pathname || '', []);
  const isOnBlackList = useMemo(
    () => BLACKLIST.some((path) => pathname.includes(path)),
    [pathname],
  );

  const showMobileBanner =
    downloadMobileAppBanner &&
    isMobileBannerOpen &&
    isMobile &&
    !isOnBlackList &&
    showABTest &&
    isLoggedIn;

  const handleBannerClose = () => {
    track({
      trackName: 'Web Banner Closed',
      properties: {
        cta_name: 'x',
        experience: TRACK_EXPERIENCE,
        invite_type: TRACK_DEVICE,
      },
    });
    toggleMobileBanner(false);
  };

  const handleBannerOpen = () => {
    track({
      trackName: 'App Download Started',
      properties: {
        cta_name: CTA_BUTTON_TEXT,
        user_id: userInfo?.user?.userId,
        experience: TRACK_EXPERIENCE,
        invite_type: TRACK_DEVICE,
        position_location: appMobileLink,
      },
    });
  };

  useEffect(() => {
    identify({
      traits: {
        featureFlagName: OPTIMIZELY_FEATURES_FLAGS.SMART_BANNER_DOWNLOAD,
        experimentName,
        variationName,
      },
    });
    track({
      trackName: 'Experiment Viewed',
      properties: {
        feature_flag_name: OPTIMIZELY_FEATURES_FLAGS.SMART_BANNER_DOWNLOAD,
        experiment_name: experimentName,
        variation_name: variationName,
      },
    });
  }, [experimentName, variationName]);

  if (!showMobileBanner) return null;

  return (
    <div className={styles.container} id="mobile-web-banner">
      <div className={styles.appContainer}>
        <button
          type="button"
          onClick={handleBannerClose}
          className={styles.dbClose}
          data-testid="mobile-banner-close-button"
        >
          <p className={styles.dbCloseIcon}>
            <i className="far fa-times" />
          </p>
        </button>
        <img src={fffAppLogo} alt="app-logo" className={styles.logoImage} />
        <div className={styles.details}>
          <div className={styles.title}>FabFitFun</div>
          <div className={styles.description}>Open in the FabFitFun app</div>
        </div>
      </div>
      <a
        type="button"
        href={appMobileLink}
        onClick={handleBannerOpen}
        className={styles.openButton}
        data-testid="mobile-banner-open-button"
      >
        {CTA_BUTTON_TEXT.toUpperCase()}
      </a>
    </div>
  );
};

export default WithABTest(
  OPTIMIZELY_FEATURES_FLAGS.SMART_BANNER_DOWNLOAD,
  MobileWebBanner,
  [MOBILE_BANNER_VARIATIONS.VERSION_1],
);
