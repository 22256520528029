import React from 'react';
import classNames from 'classnames';

import styles from './Header.module.css';

const Header = ({ closeCart, isPublicShopping, itemsQty }) => {
  return (
    <div className={styles.container}>
      <span className={styles.header}>
        {isPublicShopping ? `My Cart (${itemsQty})` : 'My Carts'}
      </span>
      <button
        type="button"
        className={classNames('cart-button', styles.closeButton)}
        onClick={closeCart}
        data-testid="cart-close-button"
      >
        <i className="fal fa-times" />
      </button>
    </div>
  );
};

export default Header;
