import React from 'react';
import classNames from 'classnames';
import includes from 'lodash/includes';
import get from 'lodash/get';

import { CART_TYPE } from '../../../services/utils/types';
import { SALE_TYPES } from '../../../services/utils/campaign';

import UpdateCartButton from '../../../shared/components/UpdateCartButton/UpdateCartButton';
import IncrementButton from '../../../shared/components/IncrementButton/IncrementButton';

import styles from './CartItem.module.css';

const SHIPPING_RESTRICTION_TOOLTIP =
  'This product cannot be shipped to you based on the Shipping Address on file.';
const CADENCE_MAP = {
  NINETY_DAYS: 'Ships every 90 days',
  GO_WITH_BOX: 'First order ships immediately, renewals ship with your Seasonal Box.',
  GO_WITH_BOX_EDIT: 'Ships every season with box',
};
const CADENCE_TYPES = {
  NINETY_DAYS: 'NINETY_DAYS',
  GO_WITH_BOX: 'GO_WITH_BOX',
};

const CartItem = ({
  id,
  cartDisplayName,
  quantity,
  onAddItem,
  imageUrl,
  soldOut,
  onRemoveItem,
  onClickProduct,
  cartLimit,
  inventoryLimit,
  users,
  shippingRestrictions,
  userShippingRestriction,
  cartType,
  displayPrice,
  strikethroughPrice,
  sponsored,
  cadenceType,
  campaignType,
  isAdditionalChoice,
  isLuxeChoice,
}) => {
  const isVariantAtPurchaseLimit = quantity === cartLimit || isLuxeChoice;
  const isOverInventoryLimit = quantity >= inventoryLimit;
  const isRestricted = includes(shippingRestrictions, userShippingRestriction);
  const isSubscribableShopVariant = !!cadenceType;
  const itemName = (
    <>
      {isLuxeChoice && <b>Luxe Choice: </b>}
      {isAdditionalChoice && !isLuxeChoice && <b>Additional Choice: </b>}
      {cartDisplayName}
      {!!isSubscribableShopVariant && ' Subscription'}
    </>
  );
  const displayShopWarningNote = cartType === CART_TYPE.shop && quantity > inventoryLimit;

  const getCadenceCopy = () => {
    if (campaignType === SALE_TYPES.EDIT && cadenceType === CADENCE_TYPES.GO_WITH_BOX) {
      return CADENCE_MAP.GO_WITH_BOX_EDIT;
    }
    return CADENCE_MAP[cadenceType];
  };

  const renderShopWarningNote = () => {
    const defaultNote = `Only ${inventoryLimit} of this item are available. Please update quantity.`;
    const warningNote = {
      0: 'This item is sold out. Please remove it.',
      1: 'Only 1 of this item is available. Please update quantity.',
    };
    const note = (message) => (
      <div className={styles.warning}>
        <p>{message}</p>
      </div>
    );
    const message = get(warningNote, inventoryLimit, defaultNote);
    return note(message);
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.additionalChoiceContainer]: isAdditionalChoice,
      })}
      onClick={isAdditionalChoice ? onClickProduct : undefined}
    >
      <div className={styles.variant}>
        {isRestricted ? (
          <span
            data-tip={SHIPPING_RESTRICTION_TOOLTIP}
            data-for="shipping-restriction"
            className={styles.imageLink}
          >
            <img alt="product" src={imageUrl} />
          </span>
        ) : (
          <button
            type="button"
            className={classNames(styles.nakedButton, styles.imageLink)}
            onClick={onClickProduct}
            data-dd-action-name={`${itemName} link image in the cart`}
          >
            <img alt="product" src={imageUrl} />
          </button>
        )}
        <div className={styles.content}>
          {isRestricted ? (
            <span data-tip={SHIPPING_RESTRICTION_TOOLTIP} data-for="shipping-restriction">
              {itemName}
            </span>
          ) : (
            <button
              type="button"
              className={classNames(styles.nakedButton, styles.name)}
              onClick={onClickProduct}
              data-dd-action-name={`${itemName} link button in the cart`}
            >
              {itemName}
            </button>
          )}
          {isSubscribableShopVariant && (
            <div className={styles.cadence} data-testid="cart-item-cadence">
              {getCadenceCopy()}
            </div>
          )}
          {cartType === CART_TYPE.social ? (
            <UpdateCartButton
              id={id}
              quantity={quantity}
              onAddItem={onAddItem}
              soldOut={soldOut}
              onRemoveItem={onRemoveItem}
              cartLimit={cartLimit}
              isVariantAtPurchaseLimit={isVariantAtPurchaseLimit}
              isRestricted={isRestricted}
            />
          ) : (
            <IncrementButton
              id={id}
              quantity={quantity}
              isVariantAtPurchaseLimit={isVariantAtPurchaseLimit}
              onAddItem={onAddItem}
              soldOut={soldOut}
              isOverInventoryLimit={isOverInventoryLimit}
              onRemoveItem={onRemoveItem}
              sponsored={sponsored}
            />
          )}
        </div>
        <div className={styles.right}>
          <span className={styles.price}>{`$${displayPrice}`}</span>
          {strikethroughPrice && (
            <span
              className={classNames(styles.price, styles.strikethroughPrice)}
            >{`$${strikethroughPrice}`}</span>
          )}
        </div>
      </div>
      {displayShopWarningNote && renderShopWarningNote()}
    </div>
  );
};

export default CartItem;
