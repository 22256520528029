import { getEnv } from "../environment";
import IUserAccount from "../../interfaces/IMyAccount";
import request from "./request";

const fetchAccountInfo = async (): Promise<IUserAccount> => {
  const response = await request({ path: getEnv().REACT_APP_ACCOUNT_INFO_URL });
  return response;
};

export default fetchAccountInfo;
