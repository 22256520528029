import { getEnv } from '../environment';
import request from './request';

export const getCampaigns = async () => {
  return request({
    path: `${getEnv().REACT_APP_ACE_API_URL}/api/1/campaigns/default`,
    method: 'GET',
    json: true,
  });
};

export const getCustomizeCampaign = async ({
  newMember,
  preview = false,
}: {
  newMember: boolean;
  preview?: boolean;
}) => {
  const uuid = window.localStorage.getItem('uuid');
  if (newMember) {
    return request({
      path: `${getEnv().REACT_APP_CUSTOMIZE_API_URL}/customize/new-members/self/campaign-state`,
    });
  } else if (preview && !!uuid) {
    return request({
      path: `${
        getEnv().REACT_APP_CUSTOMIZE_API_URL
      }/customize/v1/public/campaign-state/users/${uuid}`,
    });
  } else {
    return request({
      path: `${getEnv().REACT_APP_CUSTOMIZE_API_URL}/customize/self/campaign-state`,
    });
  }
};
